import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      window: {
        width: 0,
        height: 0
      }
    }
  }

  componentDidMount() {
    this.getWindowState();
    window.addEventListener('resize', this.getWindowState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getWindowState);
  }

  getWindowState = () => {
    this.setState({
        window: {
        width: window.innerWidth,
        height: window.innerHeight
        }
    })
  }

  render() {
    const {window} = this.state;

    const styles = {
      quote: {
        float: window.width > 740 ? 'right' : 'none',
        backgroundColor: 'rgb(249, 253, 228)',
        maxWidth: 450,
        position: 'relative',
        left: window.width > 1170 ? 100 : 0,
        marginLeft: window.width > 1170 ? -100 : 10,
        marginBottom: 20,
        padding: 20,
        borderLeft: '5px solid rgb(122, 142, 87)'
      },
      quoteText: {
        fontSize: 28,
        letterSpacing: 1,
        //fontStyle: 'italic',
        lineHeight: '1em'
      },
      quoteAuthor: {
        fontSize: 14,
      }
    }

    return(
      <Layout metadata={this.props.data.site.siteMetadata} currentPage="Home">
        <div>
          <h1>Home</h1>
          <div>
            <p>We live in a busy world that is constantly changing and is becoming increasingly complex. 
              Consequently, people are faced with many different types of experiences that could be difficult 
              to manage and to cope with.</p>
            <div style={styles.quote}>
              <span style={styles.quoteText}>It is better to light a candle than curse the darkness</span>
              <div style={styles.quoteAuthor}>-Eleanor Roosevelt</div>
            </div>
            <p>While many of these experiences can be dealt with by the person themselves, some problems or events 
              occur that seem insurmountable and could cause much emotional pain. A person may be able to call on 
              family or friends for help and support in times of need but if that isn’t enough maybe a 
              counsellor/psychotherapist may be a good option.</p>
            <p>There are many reasons why people may suffer emotional pain or may find it difficult to move forward, 
              such as anxiety, decision making, bullying, relationship difficulties, bereavement/loss, work related 
              issues, personal growth, self esteem, trauma, abuse, depression and interpersonal conflict.</p>
            <p>I provide counselling and psychotherapy in the Galway area and my office is at Ross House, Victoria Place, 
              Eyre Square in Galway City.</p>
            <p>You can <a href="mailto:info@mairemcandrew.com">email me</a> or call me at: 086 442 5401</p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
